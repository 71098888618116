import React, { useState } from "react"

import "./portfolio.scss"

const PortFolioCard = ({ title, description, link, img }) => {
  const [showDetail, setShowDetail] = useState(false)
  const toggleDetail = () => setShowDetail(!showDetail)
  return (
    <div className="porftolio__card">
      <button
        className="porftolio__card__button"
        onClick={toggleDetail}
        aria-label="Show portfolio content"
      ></button>
      <div
        className="portfolio__card__content"
        style={{ backgroundImage: `url(${img})` }}
      />
      <div
        className="portfolio__card__text"
        style={{
          top: showDetail ? "0px" : "250px",
          paddingTop: showDetail ? "25px" : "10px",
        }}
      >
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <a
        className="portfolio__card__link"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ bottom: showDetail ? "20px" : "-230px" }}
      >
        See More
      </a>
    </div>
  )
}

export default PortFolioCard
