import React from "react"

import QuickTripImg from "../../images/quicktrip.png"
import HopdoddyImg from "../../images/hopdoddy.png"
import LpqImage from "../../images/lpq.png"
import GhenghisImage from "../../images/ghenghisgrill.png"
import SevenElevenImage from "../../images/seveneleven.jpg"
import SunocoImage from "../../images/sunoco.png"
import PortfolioCard from "../../components/PortfolioCard"

const Api = () => (
  <div id="portfolio-web">
    <div id="portfolio-description">
      <p>
        I have contributed to many projects in a full stack role. Many of them
        are internal/proprietary but below are some of the projects that are
        public that you can visit
      </p>
    </div>
    <div id="portfolio-cards-web" className="portfolio__group">
      <PortfolioCard
        title="7-Eleven Loyalty"
        description="Contributed to Python packages & server infrastructure for the 7-eleven loyalty app."
        img={SevenElevenImage}
        link="https://www.7-eleven.com/7rewards"
      />
      <PortfolioCard
        title="Le Pain Quotidien"
        description="An ordering website for a global restaurant chain. I worked on some of the ordering UI/interface, menu APIs, and server infrastructure."
        img={LpqImage}
        link="https://www.lepainquotidien.com/us/en/menu"
      />
      <PortfolioCard
        title="Allstate QuickTrip"
        description="QuickTrip is a route planning app that implements a (naive) solution to the traveling salesman problem for route planning while also helping you integrate vehicle maintenance."
        img={QuickTripImg}
        link="https://appadvice.com/app/quicktrip-route-planner/1161861058"
      />
      <PortfolioCard
        title="Ghenghis Grill"
        description="A custom menu ordering system that integrated with point of sale in restaurants to facilitate ordering food online while maintaining a custom brand experience."
        img={GhenghisImage}
        link="https://www.genghisgrill.com/how-it-works"
      />
      <PortfolioCard
        title="Hopdoddy"
        description="A custom menu ordering system that integrated with point of sale in restaurants to facilitate ordering food online while maintaining a custom brand experience."
        img={HopdoddyImg}
        link="https://www.hopdoddy.com/menu"
      />
      <PortfolioCard
        title="Sunoco"
        description="Helped implement server side rendering for the Sunoco.com website."
        img={SunocoImage}
        link="https://www.sunoco.com/about-us"
      />
    </div>
  </div>
)

export default Api
